import React from "react";
import { motion } from "framer-motion";
import "./index.scss";
import { Button } from "react-bootstrap";

// const sgMail = require("@sendgrid/mail");
// sgMail.setApiKey(process.env.SENDGRID_API_KEY);

const Contact = () => {
  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   const { name, email, message } = event.target.elements;

  //   const msg = {
  //     to: "hello@digibuddies.xyz", // replace with your own email address
  //     from: email.value,
  //     subject: "Hello Digibuddies",
  //     text: `
  //       Name: ${name.value}
  //       Email: ${email.value}
  //       Message: ${message.value}
  //     `,
  //   };

  //   try {
  //     await sgMail.send(msg);
  //     console.log("Message sent successfully");
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0 }}
      whileInView={{ opacity: 1, scale: 1 }}
      transition={{ type: "tween", stiffness: 30, duration: 0.5, delay: 0.5 }}
      viewport={{ once: true, amount: 0 }}
      className="contact-content"
      id="contact"
    >
      <div className="container">
        <h2>We’d love to hear from you</h2>
        <p className="subtitle">
          Whether you’re curious about Digibuddies, or even press—we’re ready to
          answer any questions.
        </p>
        <div className="contact-main-content row">
          <div className="col-lg-6">
            <form
              className="d-flex flex-column gap-4 contact-submit"
              // onSubmit={}
            >
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="inputName1">Your Full Name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="inputName1"
                    placeholder="Full Name"
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="inputEmail">Email</label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  id="inputEmail"
                  placeholder="you@company.com"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputrequirement">Message</label>
                <textarea
                  className="form-control"
                  name="message"
                  id="inputrequirement"
                  rows="7"
                ></textarea>
              </div>
              <Button
                type="submit"
                className="buy-button mt-4"
                variant="success"
              >
                Submit
              </Button>
            </form>
          </div>
          <div className="col-lg-6">
            <div className="follow-us">
              <div className="follow-content">
                <img
                  className="follow-img"
                  alt="follow"
                  src="/Ash Jarrett Venture Sailor & Wilder - Family Session 2022-91 1.png"
                />
                <div className="follow-main">
                  <h2>Follow Us</h2>
                  <div className="d-flex gap-4">
                    <a
                      href="https://www.instagram.com/digibuddies.xyz/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/instagram-white.png"
                        alt="instagram"
                      />
                    </a>
                    <a
                      href="https://twitter.com/Digibuddiesxyz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/twitter-white.png"
                        alt="twitter"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Contact;
