import React from "react";
import Mint from "../component/Mint";
import Faq from "../component/Faq";

const MintPage = () => {
  return (
    <>
      <Mint />
      <Faq />
    </>
  );
};

export default MintPage;
